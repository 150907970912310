import {
  AbortMultipartUploadCommand,
  CompleteMultipartUploadCommand,
  CreateMultipartUploadCommand,
  GetObjectCommand,
  ListPartsCommand,
  S3Client,
  UploadPartCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

export default class AwsS3MultipartUploadHelper {
  constructor(options) {
    this.options = {
      endpoint: options.endpoint || null,
      region: options.region || 'us-east-1',
      bucket: options.bucket,
      acl: options.acl || 'public-read',
      expires: options.expires || 5 * 60,
      prefix: options.prefix || null,
      forcePathStyle: options.forcePathStyle || false,
      serviceAlias: options.serviceAlias,
    };

    const credentials = {
      accessKeyId: options.credentials.accessKeyId,
      secretAccessKey: options.credentials.secretAccessKey,
      sessionToken: options.credentials.sessionToken,
    };

    this.s3 = new S3Client({
      credentials,
      signatureVersion: 'v4',
      endpoint: this.options.endpoint,
      region: this.options.region,
      forcePathStyle: this.options.forcePathStyle,
      correctClockSkew: true,
      retryDelayOptions: {
        base: 100,
        customBackoff: (retryCount) => {
          if (retryCount > 2) return -1;

          return (retryCount + 1) * 200;
        },
      },
    });
  }

  createMultipartUpload(file) {
    return new Promise((resolve, reject) => {
      const key = file.name;

      this.s3
        .send(
          new CreateMultipartUploadCommand({
            Bucket: this.options.bucket,
            Key: key,
            ACL: this.options.acl,
            ContentType: file.type,
          }),
        )
        .then((data) =>
          resolve({
            key: data.Key,
            uploadId: data.UploadId,
          }),
        )
        .catch((error) => reject(error));
    });
  }

  listParts(file, { key, uploadId }) {
    return new Promise((resolve, reject) => {
      let parts = [];

      const listPartsPart = (part) => {
        this.s3
          .send(
            new ListPartsCommand({
              Bucket: this.options.bucket,
              Key: key,
              UploadId: uploadId,
              PartNumberMarker: part,
            }),
          )
          .then((data) => {
            parts = parts.concat(data.Parts);
            if (data.IsTruncated) {
              listPartsPart(data.NextPartNumberMarker);
            } else {
              resolve(parts);
            }
          })
          .catch((error) => reject(error));
      };

      listPartsPart(0);
    });
  }

  prepareUploadPart(file, { uploadId, key, partNumber }) {
    return new Promise((resolve, reject) => {
      const command = new UploadPartCommand({
        Bucket: this.options.bucket,
        Key: key,
        UploadId: uploadId,
        PartNumber: partNumber,
        Body: '',
      });

      getSignedUrl(this.s3, command, { expiresIn: this.options.expires })
        .then((url) => resolve({ partNumber, url }))
        .catch((error) => reject(error));
    });
  }

  prepareUploadParts(file, partData) {
    return new Promise((resolve, reject) => {
      const promises = partData.parts.map((part) =>
        this.prepareUploadPart(file, {
          uploadId: partData.uploadId,
          key: partData.key,
          partNumber: part.number,
        }),
      );

      Promise.all(promises)
        .catch(() => reject({ source: { status: 500 } }))
        .then((preparedParts) => {
          const presignedUrls = {};

          for (const preparedPart of preparedParts) {
            presignedUrls[preparedPart.partNumber.toString()] = preparedPart.url;
          }

          resolve({ presignedUrls });
        });
    });
  }

  abortMultipartUpload(file, { key, uploadId }) {
    return new Promise((resolve) => {
      this.s3
        .send(
          new AbortMultipartUploadCommand({
            Bucket: this.options.bucket,
            Key: key,
            UploadId: uploadId,
          }),
        )
        .then(() => resolve({}))
        .catch(() => resolve({}));
    });
  }

  completeMultipartUpload(file, { key, uploadId, parts }) {
    return new Promise((resolve, reject) => {
      this.s3
        .send(
          new CompleteMultipartUploadCommand({
            Bucket: this.options.bucket,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: {
              Parts: this.uniqueParts(parts),
            },
          }),
        )
        .then((data) => resolve({ location: data.Location }))
        .catch((error) => reject(error));
    });
  }

  getSignedUrl(key, { expiresIn = 24 * 60 * 60 } = {}) {
    const command = new GetObjectCommand({
      Bucket: this.options.bucket,
      Key: key,
    });

    return getSignedUrl(this.s3, command, { expiresIn });
  }

  uniqueParts(parts) {
    const addedPartNumbers = [];
    const uniqueParts = [];

    for (const part of parts) {
      if (addedPartNumbers.indexOf(part.PartNumber) < 0) {
        addedPartNumbers.push(part.PartNumber);
        uniqueParts.push(part);
      }
    }

    return uniqueParts.sort((a, b) => a.PartNumber - b.PartNumber);
  }

  keyWithPrefix(key) {
    if (!this.options.prefix) return key;

    return `${this.options.prefix}/${key}`;
  }

  get metaForUpload() {
    return {
      endpoint: this.options.endpoint,
      region: this.options.region,
      bucket: this.options.bucket,
      service_alias: this.options.serviceAlias,
    };
  }
}
